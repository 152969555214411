//to ignore cli warning about deprecated mixin
$enable-deprecation-messages: false;
$ignore-warning : true;

// Custom class not bootstrap
$fn: 'HelveticaNeue'; // fn = font normal 
$fm: 'HelveticaNeueMed'; // fm = font Medium 
$border-radius: 1.25rem;
// Body
$body-bg : #F8F8F8;
$body-color: #393939;
$headings-color: #000;
// Typography
$font-family-base: 'Roboto',
  sans-serif;
$headings-font-weight : 500;
// $headings-font-family:        $fm;




$font-size-base : 0.875rem !default;
$font-weight-base : 400 !default;
// $line-height-base: 1.6;
$h1-font-size : 2.25rem;
$h2-font-size : 1.875rem;
$h3-font-size : 1.5rem;
$h4-font-size : 1.125rem;
$h5-font-size : 1rem;
$h6-font-size : 0.938rem;


//
// Color system
//
$white : #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black : #000 !default;


$blue : #5e72e4;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink : #e83e8c !default;
$red : #EE3232;
$orange: #ff9900 !default;
$yellow: #FFFA6F;
$green : #297F00;
$teal : #20c997 !default;
$cyan : #3065D0;



$grays: (
  ) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(("100": $gray-100,
      "200": $gray-200,
      "300": $gray-300,
      "400": $gray-400,
      "500": $gray-500,
      "600": $gray-600,
      "700": $gray-700,
      "800": $gray-800,
      "900": $gray-900),
    $grays
);


$muted : #89879f;
$text-muted: #89879f;
$mine-shaft: #262626;
$ebony-clay: #232833;

$colors: (
  ) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(("blue": $blue,
      "indigo": $indigo,
      "purple": $purple,
      "pink": $pink,
      "red": $red,
      "orange": $orange,
      "yellow": $yellow,
      "green": $green,
      "teal": $teal,
      "cyan": $cyan,
      "white": $white,
      "gray": $gray-600,
      "gray-dark": $gray-800),
    $colors
);



$primary : #0E7E42 !default;
$secondary: #717579 !default;
$success : #68e365 !default;
$info : #D653C1 !default;
$warning : #FFAA2B !default;
$danger : #f72b50 !default;
$light : #c8c8c8 !default;
$dark : #6e6e6e;


:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --primary-hover: #{darken($primary, 10)};
  --primary-dark: #{darken($primary, 30)};
  --rgba-primary-1: #{rgba($primary, 0.1)};
  --rgba-primary-2: #{rgba($primary, 0.2)};
  --rgba-primary-3: #{rgba($primary, 0.3)};
  --rgba-primary-4: #{rgba($primary, 0.4)};
  --rgba-primary-5: #{rgba($primary, 0.5)};
  --rgba-primary-6: #{rgba($primary, 0.6)};
  --rgba-primary-7: #{rgba($primary, 0.7)};
  --rgba-primary-8: #{rgba($primary, 0.8)};
  --rgba-primary-9: #{rgba($primary, 0.9)};
  --font-family-base: #{$font-family-base};
  --font-family-title: #{$font-family-base};
  --title: #{$headings-color};
}




$theme-colors: (
  ) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(("primary": $primary,
      "secondary": $secondary,
      "success": $success,
      "info": $info,
      "warning": $warning,
      "danger": $danger,
      "light": $light,
      "dark": $dark),
    $theme-colors
);


// $base-font-size   : 0.8125rem !default;
$heading-primary-size: 1.8rem;
$sub-heading-size : 1.6rem !default;

$grid-gutter-width: 30px;

$dropdown-lik-color: $body-color;


$border-color : #EEEEEE;
$headings-color: #3d4465 !default;
$rounded : 1.75rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440
);

body {
  overflow-x: unset;
}

input[type="checkbox"],
input[type="checkbox"]:hover {
  accent-color: #0E7E42;
}

.nav-pills .nav-link {
  background-color: #e8e8e8;
}

.nav-header .brand-logo {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo,
.nav-header .brand-logo {
  opacity: 0;
  visibility: hidden;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
  pointer-events: none;
  background-color: transparent;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control {
  pointer-events: all;
}

.text-justify {
  text-align: justify !important;
}

.form-group label {
  font-size: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.border-b-5px {
  --size: 5px;
  border-bottom: solid var(--size);
}

.container-fluid>div:nth-of-type(2) {
  position: relative;
  z-index: 2;
}

.select-filter {
  padding: 0.3125rem 0;
  border-radius: 0.75rem;
}

.select-filter *,
.select-filter *:focus,
.select-filter *:active,
.select-filter *:hover {
  border: none !important;
  border-color: transparent !important;
}

.select-filter>div,
.select-filter>div:active,
.select-filter>div:focus,
.select-filter>div:hover {
  border-radius: 0.75rem;
  border-color: #fff !important;
}

.select-filter>div:nth-of-type(1) {
  padding: 0 1rem;
  border-color: transparent !important;
  border-radius: 0.75rem;
  box-shadow: none !important;
  border: none;
}

.select-filter>div:first-child:hover,
.select-filter>div:first-child:focus,
.select-filter>div:first-child:active {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.select-filter>div:nth-of-type(1)>div:first-child {
  border: none !important;
  padding: 0;
}

.select-filter>div:nth-of-type(1)>div:last-child>div {
  padding-right: 0;
}

.select-filter>div:nth-of-type(1)>div:last-child>div svg {
  fill: #3b3b3b;
}

.treeview-item {
  display: inline-flex;
  transition: all 0.3s ease;
}

.treeview-item:before,
.treeview-item:after {
  content: "";
  position: absolute;
  background-color: rgb(110, 110, 110);
}

.treeview-item:before {
  top: 0;
  width: 1px;
  height: 100%;
  left: 0;
}

.treeview-item:last-child:before {
  height: 50%;
}

.treeview-item:after {
  height: 1px;
  width: 16px;
  left: 0;
}

.treeview-sub-item {
  transition: all 0.3s ease;
  cursor: pointer;
}

.treeview-sub-item.active {
  background-color: #a7d5ff;

}

table {
  border-collapse: separate;
  border-spacing: 0;
}

// table th {
//   /* Apply both top and bottom borders to the <th> */
//   border-top: 2px solid !important;
//   border-bottom: 2px solid !important;
//   border-right: 2px solid !important;
// }

// table td {
//   /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
//   border-bottom: 2px solid !important;
//   border-right: 2px solid !important;
// }

// table th:first-child,
// table td:first-child {
//   /* Apply a left border on the first <td> or <th> in a row */
//   border-left: 2px solid;
// }

table.dataTable thead,
table.dataTable thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
  text-transform: unset;
  // z-index: 5;
  z-index: 1;
}

table.dataTable.no-sticky-header thead,
table.dataTable.no-sticky-header thead th {
  position: relative;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #0E7E42;
}

.equal-btn-filter,
.equal-btn-add {
  --size: 138px;
  min-width: var(--size);
  max-width: var(--size);
}

.filter-card label {
  font-size: 0.938rem;
}

.text-one-line {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-trim-line {
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 25px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.text-two-line {
  -webkit-line-clamp: 2;
}

.text-three-line {
  -webkit-line-clamp: 3;
}

.dropdown.header-profile2 .dropdown-toggle:after {
  content: none;
}

.swal-footer {
  text-align: center;
}

.swal-button--cancel:focus {
  box-shadow: none !important;
}

.disable-sort-stt th:first-child span,
.dataTable th:first-child span {
  display: none;
}

.disable-sort-stt th:first-child,
.dataTable th:first-child {
  pointer-events: none;
}

.column-permission>div>div:first-child,
.column-permission>div>div:nth-last-child(2) {
  text-align: center;
  justify-content: center;
}

.column-permission>div>div:first-child *,
.column-permission>div>div:nth-last-child(2) * {
  font-weight: bold;
  font-size: 1rem;
}

.btn-action-permission {
  background: rgb(203, 213, 224);
}

.list-permission {
  height: 34rem;
  overflow-y: auto;
}

.list-permission-header {
  background-color: #d1d5db;
}

.btn-permission.active {
  background-color: #cde5ff !important;
}

// Custom radio look like checkboxes
.radio-like-checkbox[type="radio"]:checked,
.radio-like-checkbox[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.radio-like-checkbox[type="radio"]:checked+label,
.radio-like-checkbox[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.radio-like-checkbox[type="radio"]:checked+label:before,
.radio-like-checkbox[type="radio"]:not(:checked)+label:before {
  content: "\f0c8";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  border-radius: 5px;
  background: #fff;
}

.radio-like-checkbox[type="radio"]:checked+label:after,
.radio-like-checkbox[type="radio"]:not(:checked)+label:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  position: absolute;
  font-size: 13px;
  width: 18px;
  height: 18px;
  top: 1px;
  left: 0px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.radio-like-checkbox[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radio-like-checkbox[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.react-datepicker__close-icon {
  margin-right: 0.5rem;
}

.select-land-customer {
  max-width: 15rem;
}

.react-datepicker__close-icon::after {
  background-color: #0E7E42 !important;
}

.form-control {
  height: 40px;
}

.row-khoan-muc-phi:first-child td:last-child div {
  display: none;
}

.bg-light-report {
  background-color: #f0f0f0 !important;
}

.table tfoot tr td {
  white-space: nowrap;
  border-color: #7c7c7c;
  border-bottom: 2px solid #7c7c7c;
}

table.dataTable tbody td {
  background-color: #fff !important;
  border-bottom: 2px solid;
  border-color: #7c7c7c;
}

.table-striped>tbody>tr:nth-of-type(odd),
.table-striped>tbody>tr:nth-of-type(odd) td {
  background-color: #F2F2F2 !important;
}

.table-striped>tbody>tr:nth-of-type(odd).tr-active,
.table-striped>tbody>tr:nth-of-type(odd).tr-active td,
table.dataTable tbody tr.tr-active,
table.dataTable tbody tr.tr-active td {
  background-color: #c7e3fc !important;
}

.table-striped>tbody>tr:nth-of-type(odd).active-blue,
.table-striped>tbody>tr:nth-of-type(odd).active-blue td,
table.dataTable tbody tr.tr-active-blue,
table.dataTable tbody tr.tr-active-blue td {
  background-color: #C9DAF8 !important;
}

.table-striped>tbody>tr:nth-of-type(odd).active-green,
.table-striped>tbody>tr:nth-of-type(odd).active-green td,
table.dataTable tbody tr.tr-active-green,
table.dataTable tbody tr.tr-active-green td {
  background-color: #D8EAD2 !important;
}

table.dataTable tbody tr.tr-active-purple,
table.dataTable tfoot tr.tr-active-purple,
table.dataTable tbody tr.tr-active-purple td,
table.dataTable tfoot tr.tr-active-purple td {
  background-color: #DAD2E9 !important;
}

.table-striped>tbody>tr.tr-yellow:nth-of-type(odd),
.table-striped>tbody>tr.tr-yellow:nth-of-type(odd) td,
table.dataTable tbody tr.tr-yellow,
table.dataTable tbody tr.tr-yellow td {
  background-color: #FFFF00 !important;
}

.table-striped>tbody>tr.tr-green:nth-of-type(odd),
.table-striped>tbody>tr.tr-green:nth-of-type(odd) td,
table.dataTable tbody tr.tr-green,
table.dataTable tbody tr.tr-green td {
  background-color: #00B050 !important;
}

.table-responsive {
  overflow-y: auto;
  max-height: calc(100vh - 292px);
}

.modal-content .table-responsive {
  max-height: unset;
}

.loading-modal .modal-content {
  background-color: transparent;
  border: 0;
}

// Header style
.header-left .dashboard_bar {
  font-size: 1.7rem;
}

.nav-header {
  height: 3.5rem;
}

.header {
  height: 3.8rem;
}

.dlabnav {
  z-index: 9;
  // z-index: 50;
  height: calc(100% - 61px);
  top: unset;
  bottom: 0;
}

.top-bar {
  // z-index: 9999;
  z-index: 10;
}

.metismenu {
  padding-top: 0 !important;
}

// .metismenu li.has-sub-menu ul {
//   display: none !important;
// }

// .metismenu li.mm-active ul {
//   display: block !important;
// }

.nav-control {
  font-size: 1.7rem;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
  top: 61px;
}

[data-header-position="fixed"] .header {
  top: 61px;
}

.nav-control {
  top: 60%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .slimScrollDiv,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .dlabnav-scroll {}

// Fixed styles


[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .dlabnav {
  position: absolute;
  z-index: 9;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .copyright {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .copyright {
  display: block;
}

.con-dau-huy {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-10deg);
  height: 70px;
  mix-blend-mode: multiply;
  opacity: 0.5;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: #7c7c7c;
}

.table thead th {
  border-color: #7c7c7c !important;
}

.table.table-export-report thead th {
  border-color: #000000 !important;
}

// body {
//   min-height: 200vh;
// }

// .table .header {
//   position: sticky;
//   height: auto;
//   top: 0;
//   padding-left: 0 !important;
// }

// .table .th,
// .table th {
//   border: none;
//   background-color: rgba(14, 126, 66, 0.2);
// }

// .table .td,
// .table td {
//   border: none;
// }

// .table-striped>.tbody>.tr:nth-of-type(odd) {
//   background-color: rgba(0, 0, 0, 0.05);
// }

.sidebar-menu-item a span,
.sidebar-menu-item button span {
  font-weight: 800;
  font-family: 'Nunito Sans', sans-serif;
}



@media (min-width: 768px) and (max-width: 991.98px) {
  [data-sidebar-style="mini"] .dlabnav {
    top: 5.5rem;
    z-index: 9;
  }

  [data-sidebar-style="mini"] .footer {
    padding-left: 0;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav {
    position: fixed !important;
  }

  .container-fluid.pb-5 {
    padding-top: 6rem;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .header .header-content {
    padding-left: 5rem;
  }

  .nav-control {
    right: -4rem;
  }
}

@media (min-width: 1200px) {
  #main-wrapper.menu-toggle {
    min-height: 145vh;
  }

  .header .header-content {
    padding-left: 6rem;
  }

  .nav-control {
    right: -5rem;
  }

  .container-fluid.pb-5 {
    padding-top: 4rem;
  }

  .login-main-page {
    padding: 0px 50px;
  }
}

@media (min-width: 1300px) {
  #main-wrapper.menu-toggle {
    min-height: 145vh;
  }

  .header .header-content {
    padding-left: 6rem;
  }

  .nav-control {
    right: -5rem;
  }
}

@media (min-width: 1400px) {
  #main-wrapper.menu-toggle {
    min-height: 145vh;
  }

  .header .header-content {
    padding-left: 6rem;
  }

  .nav-control {
    right: -5rem;
  }
}

@media (min-width: 1600px) {
  #main-wrapper.menu-toggle {
    min-height: 130vh;
  }

  .nav-control {
    right: -5rem;
  }

  .container-fluid.pb-5 {
    padding-top: 2rem;
  }
}

@media (min-width: 1900px) {
  #main-wrapper.menu-toggle {
    min-height: 130vh;
  }

  .container-fluid.pb-5 {
    padding-top: 2rem;
  }

  .nav-control {
    right: -5rem;
  }
}

@media (max-width: 575px) {
  .login-main-page {
    padding: 15px !important;
  }

  .dlabnav {
    bottom: -52px;
  }

  .top-bar-logo {
    width: calc(100% - 120px);
    padding-left: 20px;
  }

  .top-bar-user-info {
    max-width: 120px;
  }

  .top-bar-user-info .nav-link {
    padding-left: 5px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .top-bar-user-info img {
    --size: 35px;
    height: var(--size);
    width: var(--size);
    margin-right: 10px !important;
  }

  .top-bar-user-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70px;
  }

  .container-fluid.pb-5 {
    padding-top: 4rem;
  }
}