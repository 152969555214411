.sidebar-menu-item a,
.sidebar-menu-item.accordion-item button {
  font-size: 1.125rem;
  font-weight: 600;
  padding: 1.375rem 1.7rem;
  transition: all 0.5s;
  display: block;
  color: #737b8b;
  border: 0;
  position: relative;
}

.sidebar-menu-item.accordion-item .accordion-button:active,
.sidebar-menu-item.accordion-item .accordion-button:focus {
  box-shadow: none;
  outline: none;
}

.sidebar-menu-item.accordion-item .accordion-button {
  border-radius: 0px;
  position: relative;
  background-color: rgba(68, 129, 78, 0.1);
  color: #44814e;
}

.sidebar-menu-item-child a.mm-active {
  color: #44814e;
}

.sidebar-menu-item.accordion-item .accordion-button.collapsed {
  background-color: #fff;
  color: #737b8b;
}

.sidebar-menu-item.accordion-item .accordion-button::after {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sidebar-menu-item.accordion-item .accordion-button:not(.collapsed)::after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 25px;
}
.sidebar-menu-item i {
  width: 28px;
  text-align: left;
  display: inline-block;
  font-size: 1.3rem;
  height: auto;
  line-height: 1;
  margin-right: 1.25rem;
  position: relative;
  top: 0;
  vertical-align: middle;
}

.sidebar-menu-item-child a {
  font-size: 0.9375rem;
  padding-bottom: 0.5rem;
  padding-left: 5rem;
  padding-top: 0.5rem;
  position: relative;
  transition: all 0.5s;
}

.sidebar-menu-item-child a:hover {
  padding-left: 5.3rem;
}

.sidebar-menu-item-child a::before {
  content: "";
  width: 10px;
  height: 1.5px;
  transition: all 0.5s;
  background-color: rgba(68, 129, 78, 0.5);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 5;
  left: 10%;
}

.sidebar-menu-item-child a:hover:before {
  width: 20px;
}

.sidebar-menu-item.mm-active a {
  background-color: rgba(68, 129, 78, 0.1);
}

.sidebar-menu-item > a:before,
.sidebar-menu-item.accordion-item .accordion-button:before {
  content: "";
  background: #ffaa2b;
  border-radius: 1.25rem;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.5s;
  width: 0;
}
.sidebar-menu-item.mm-active > a:before,
.sidebar-menu-item.accordion-item .accordion-button:before {
  width: 0.25rem;
}

.sidebar-menu-item.accordion-item .accordion-button.collapsed:before {
  content: none;
}

.sidebar-menu-item.mm-active > a,
.sidebar-menu-item.mm-active > a span {
  color: #44814e;
}

.sidebar-menu-item .sidebar-menu-item button {
  pointer-events: none;
  padding: 0rem 0rem 0rem 1.7rem;
}

.sidebar-menu-item .sidebar-menu-item button.accordion-button:after {
  content: none;
}

.sidebar-menu-item .sidebar-menu-item .collapse:not(.show) {
  display: block;
}

.show.menu-toggle .sidebar-menu-item .accordion-button span,
.show.menu-toggle .sidebar-menu-item.accordion-item .accordion-button::after,
.show.menu-toggle .sidebar-menu-item > a:before,
.show.menu-toggle .sidebar-menu-item.accordion-item .accordion-button:before {
  display: none;
}

.show.menu-toggle .sidebar-menu-item {
  padding: 0 1.125rem;
  transition: all 0.4s ease-in-out;
}
.show.menu-toggle .sidebar-menu-item > a,
.show.menu-toggle .sidebar-menu-item .accordion-button {
  border-radius: 3rem;
  padding: 1.25rem 1.125rem;
  text-align: center;
  margin: 0.125rem 0;
}

.show.menu-toggle .sidebar-menu-item a i,
.show.menu-toggle .sidebar-menu-item .accordion-button i {
  text-align: center;
}

.show.menu-toggle
  .sidebar-menu-item
  .sidebar-menu-item-child
  .sidebar-menu-item
  .accordion-header
  button {
  display: flex;
}

.show.menu-toggle
  .sidebar-menu-item
  .sidebar-menu-item-child
  .sidebar-menu-item
  .accordion-header
  button
  span {
  display: block;
}

.show.menu-toggle
  .sidebar-menu-item
  .sidebar-menu-item-child
  .sidebar-menu-item
  .accordion-collapse {
  position: relative;
  left: 0;
}

@media (min-width: 1200px) {
  .show.menu-toggle .sidebar-menu-item-child a,
  .show.menu-toggle .sidebar-menu-item-child a:hover {
    padding: 15px;
  }
  .show.menu-toggle .dlabnav .accordion-collapse.collapse {
    position: absolute;
    min-width: 15rem;
    background: #fff;
    display: none;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    left: 5.5rem;
    top: 0;
    z-index: 5;
  }

  .show.menu-toggle
    .sidebar-menu-item.accordion-item:hover
    .accordion-collapse.collapse {
    display: block;
  }

  .show.menu-toggle .sidebar-menu-item-child a::before {
    content: none;
  }
}
